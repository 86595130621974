import { Button, ButtonGroup, FormControl, FormErrorMessage, VisuallyHidden } from '@chakra-ui/react';
import { GoogleIcon } from './ProviderIcons';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../../../config/auth/firebase';
import { useAuth } from '../../../context/AuthContext';
import { getFirebaseErrorMessageFromErrorCode } from '../../../helpers/firebaseHelpers';
import { useState } from 'react';
import { registerPosthogEvent } from '../../../helpers/postHogEventHelpers';
import { EVENT_NAME } from '../../../utils/constants/postHogConstants';

const providers = [{ name: 'Google', icon: <GoogleIcon />, provider: new GoogleAuthProvider() }];

export const OAuthButtonGroup = () => {
    const { postLogin } = useAuth();
    const [errorMessage, setErrorMessage] = useState('');

    const handleClick = (provider: GoogleAuthProvider, name: string) => {
        setErrorMessage('');

        let isSuccessful: boolean | undefined = undefined;
        let _errorMessage: string | null = null;
        signInWithPopup(auth, provider)
            .then((result) => {
                postLogin(result);
                isSuccessful = true;
            })
            .catch((error) => {
                _errorMessage = getFirebaseErrorMessageFromErrorCode(error.code);
                setErrorMessage(_errorMessage);
                isSuccessful = false;
            })
            .finally(() => {
                registerPosthogEvent(EVENT_NAME.clickAuthenticateButton, {
                    authenticationType: name,
                    isSuccessful: isSuccessful,
                    errorMessage: _errorMessage
                });
            });
    };

    return (
        <>
            <ButtonGroup variant="secondary" spacing="4">
                {providers.map(({ name, icon, provider }) => (
                    <Button
                        variant={'outline'}
                        key={name}
                        flexGrow={1}
                        onClick={() => handleClick(provider, name)}
                        data-testid={`${name}-oauth-button`}
                    >
                        <VisuallyHidden>Sign in with {name}</VisuallyHidden>
                        {icon}
                    </Button>
                ))}
            </ButtonGroup>
            <FormControl isInvalid={!!errorMessage}>
                <FormErrorMessage>{errorMessage}</FormErrorMessage>
            </FormControl>
        </>
    );
};
