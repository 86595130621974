import { Divider, HStack, Stack, Text } from '@chakra-ui/react';
import { OAuthButtonGroup } from './OAuthButtonGroup';
import { AuthForm } from './AuthForm';
import { AuthContainer } from '../../Containers/AuthContainer';
import { AuthPageType } from '../../../types/types';

interface AuthModalProps {
    authPageVersion?: AuthPageType;
}

export const AuthModal = ({ authPageVersion = 'signup' }: AuthModalProps) => {
    return (
        <AuthContainer showForgotPwLink={authPageVersion === 'signin'} authPageVersion={authPageVersion}>
            <AuthForm />
            <Stack spacing="6" pt="4">
                <HStack>
                    <Divider />
                    <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted">
                        or continue with
                    </Text>
                    <Divider />
                </HStack>
                <OAuthButtonGroup />
            </Stack>
        </AuthContainer>
    );
};
