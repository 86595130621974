import { Button, FormControl, FormErrorMessage, FormLabel, Input, Spinner, Stack } from '@chakra-ui/react';
import { auth } from '../../../config/auth/firebase';
import { FormEvent, useState } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { getFirebaseErrorMessageFromErrorCode, getFirebaseSignInMethod } from '../../../helpers/firebaseHelpers';
import { PasswordField } from './PasswordField';
import { registerPosthogEvent } from '../../../helpers/postHogEventHelpers';
import { EVENT_NAME } from '../../../utils/constants/postHogConstants';
import { signInWithEmailAndPassword } from 'firebase/auth';

export const AuthForm = () => {
    const { postLogin } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e: FormEvent) => {
        setIsLoading(true);
        e.preventDefault();
        setErrorMessage('');

        let isSuccessful: boolean | undefined = undefined;
        let _errorMessage: string | null = null;

        try {
            const firebaseAuthMethodFunction = await getFirebaseSignInMethod(auth, email);
            await firebaseAuthMethodFunction(auth, email, password)
                .then((result) => {
                    postLogin(result);
                    isSuccessful = true;
                })
                .catch((error) => {
                    _errorMessage = getFirebaseErrorMessageFromErrorCode(error.code);
                    setErrorMessage(_errorMessage);
                    isSuccessful = false;
                })
                .finally(() => {
                    setIsLoading(false);
                    registerPosthogEvent(EVENT_NAME.clickAuthenticateButton, {
                        isSuccessful,
                        authenticationType:
                            typeof firebaseAuthMethodFunction === typeof signInWithEmailAndPassword
                                ? 'signInWithEmailAndPassword'
                                : 'createUserWithEmailAndPassword',
                        errorMessage: _errorMessage
                    });
                });
        } catch (error) {
            if (typeof error === 'string') {
                setErrorMessage(error);
            } else {
                setErrorMessage('An error occurred. Please try again.');
            }
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <Spinner />;
    } else {
        return (
            <Stack spacing="6" as={'form'} onSubmit={handleSubmit}>
                <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input type="email" value={email} onChange={(e) => setEmail(e.currentTarget.value)} data-testid="auth-form-email-input" />
                </FormControl>
                <PasswordField value={password} onChange={(e) => setPassword(e.currentTarget.value)} />

                <Stack>
                    <Button type="submit" mt={6} data-testid={'auth-form-submit-button'}>
                        Continue
                    </Button>
                    <FormControl isInvalid={!!errorMessage}>
                        <FormErrorMessage>{errorMessage}</FormErrorMessage>
                    </FormControl>
                </Stack>
            </Stack>
        );
    }
};
